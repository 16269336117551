import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import ChatMessage from '@/core/models/ChatMessage'
import LoggedUserModel from '@/core/models/LoggedUserModel'

import { REFRESH_CHAT_MESSAGES, SUBMIT_MESSAGE, SUBMIT_PHOTO } from '@/store/root/actions'

import { GET_LAST_NOT_MY_MESSAGE, GET_LOGGED_USER, GET_MESSAGES } from '@/store/root/getters'

import Chat from '@/components/shared/Chat/Chat.vue'
import moment from 'moment'

import { RefreshChatMessagesPayload } from '@/store/root/types'


@Component({
  components: { Chat }
})
export default class ChatPage extends Vue {
  /** Id of current chat. */
  @Prop()
  public id: string;

  @Action(REFRESH_CHAT_MESSAGES)
  public refreshChatMessages: (payload: RefreshChatMessagesPayload) => Promise<void>

  @Getter(GET_MESSAGES)
  public getMessages: ChatMessage[]

  @Getter(GET_LOGGED_USER)
  public loggedUser: LoggedUserModel | null

  @Getter(GET_LAST_NOT_MY_MESSAGE)
  public lastNotMyMessage: ChatMessage | null

  @Action(SUBMIT_MESSAGE)
  public submitMessage: (message: ChatMessage) => void

  @Action(SUBMIT_PHOTO)
  public submitPhoto: (photoValue: File) => Promise<string>

  public STATUS_SENDED = 'sent';

  public STATUS_VIEWED = 'viewed';


  public linkOptions: any = {
    my: {
      className: 'myLinkClass',
      events: {
        click: function (e: any) {
          alert('Link clicked!') // TODO!!!
        },
        mouseover: function (e: any) {
          alert('Link hovered!') // TODO!!!
        }
      },
      format: function (value: any, type: any) {
        if (type === 'url' && value.length > 50) {
          value = value.slice(0, 50) + '…'
        }
        return value
      }
    },
    others: {
      className: 'othersLinkClass',
      events: {
        click: function (e: any) {
          alert('Link clicked!') // TODO!!!
        },
        mouseover: function (e: any) {
          alert('Link hovered!') // TODO!!!
        }
      },
      format: function (value: any, type: any) {
        if (type === 'url' && value.length > 50) {
          value = value.slice(0, 50) + '…'
        }
        return value
      }
    }
  }

  public onType (event: any): void {
    // here you can set any behavior
  }

  public onMessageSubmit (message: ChatMessage): void {
    this.submitMessage(message)
  }

  public async onImageSelected (message: ChatMessage, imageFile: File): Promise<void> {
    const url = await this.submitPhoto(imageFile)

    const attachment = message.attachments![0]
    attachment.image = url
    attachment.preview = url

    this.submitMessage(message)
  }

  public onImageClicked (message: any): void {
    /**
     * This is the callback function that is going to be executed when some image is clicked.
     * You can add your code here to do whatever you need with the image clicked. A common situation is to display the image clicked in full screen.
     */
    console.log('Image clicked', message.image)
  }

  public checkNewChatMessagesInInterval (seconds: number) {
    const REFRESH_AFTER_LAST_MESSAGE_SECONDS = 15

    setInterval(() => {
      if (!this.loggedUser) {
        return
      }

      const lastNotMyMessagePassSeconds = moment().diff(moment(this.lastNotMyMessage?.createdAt || 0), 'seconds')

      if (lastNotMyMessagePassSeconds < REFRESH_AFTER_LAST_MESSAGE_SECONDS) {
        return
      }

      this.refreshChatMessages({ chatId: this.id, from: this.lastNotMyMessage?.createdAt })
    }, seconds * 1000)
  }

  public async mounted () {
    console.log('mounted!')
  }

  /**
   * Created lifecycle hook. Fires when component instance is created.
   */
  public async created () {
    console.log(`Chatpage(${this.id}), load messages`)
    await this.refreshChatMessages({ chatId: this.id })
    console.log('Messages=', this.getMessages)

    // todo: load last state from chatsList.messages
    //   render from messages and users
    //   update messagesList and chatsList.messages states

    this.checkNewChatMessagesInInterval(30)
  }
}
